import React from "react"
import { graphql, PageProps } from "gatsby"

import { FlightsDepartures, TemplateProps } from "../templates"
import ImageHero from "../../components/Hero/ImageHero"
import DepartureFlights from "../../components/Flights/Departures/DepartureFlights"
import { SEO } from "../../components/SEO"

const FlightsDeparturesPage: React.FC<PageProps> = ({
  data,
  location,
}: TemplateProps<FlightsDepartures>) => {
  const { title, featuredImage } = data.wpPage
  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <ImageHero image={featuredImage} />
      <DepartureFlights title={title} />
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      slug
      featuredImage {
        node {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default FlightsDeparturesPage
