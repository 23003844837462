import React, { useState, useEffect, useContext, useMemo, useRef } from "react"
import tw from "twin.macro"
import TranslateClient from "../../../clients/TranslateClient"
import { LanguageContext } from "../../../context/Language"
import { FlightInfo } from "../Flights"
import FlightsTable from "../FlightsTable"
const { getStringValuesArray, mapValuesToObject } = require("../../../helpers")
import loader from "../arrivals.json"
import lottie from "lottie-web"
import FlightClient from "../../../clients/FlightClient"

interface PageData {
  title: string
}

const DepartureFlights: React.FC<PageData> = ({ title }) => {
  const airlineText = "Airline"
  const cityText = "City"
  const flightText = "Flight"
  const scheduledTimeText = "Scheduled Time"
  const estimatedTimeText = "Estimated Time"
  const gateText = "Gate"
  const statusText = "Status"
  const viewAllArrivals = "View All Arrivals"
  const viewAllDepartures = "View All Departures"
  const lastUpdatedText = "Last updated"
  const [text, setText] = useState({
    title,
    airlineText,
    cityText,
    flightText,
    scheduledTimeText,
    estimatedTimeText,
    gateText,
    statusText,
    viewAllArrivals,
    viewAllDepartures,
    lastUpdatedText,
  })
  const { language } = useContext(LanguageContext)
  const [firstLoad, setFirstLoad] = useState(true)
  const animationRef = useRef(null)

  useEffect(() => {
    if (language === "en") {
      setText({
        title,
        airlineText,
        cityText,
        flightText,
        scheduledTimeText,
        estimatedTimeText,
        gateText,
        statusText,
        viewAllArrivals,
        viewAllDepartures,
        lastUpdatedText,
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          title,
          airlineText,
          cityText,
          flightText,
          scheduledTimeText,
          estimatedTimeText,
          gateText,
          statusText,
          viewAllArrivals,
          viewAllDepartures,
          lastUpdatedText,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  //Define static headers
  const flightHeaders = [
    { name: text?.airlineText },
    { name: text?.cityText },
    { name: text?.flightText },
    { name: text?.scheduledTimeText },
    { name: text?.estimatedTimeText },
    { name: text?.gateText },
    { name: text?.statusText },
  ]
  const mobileFlightHeader = [
    { name: text?.cityText },
    { name: text?.flightText },
    { name: text?.scheduledTimeText },
    { name: text?.statusText },
  ]

  //Create new date, formatting into a readable context, using the locale of the browser.
  var updateDate = new Date()
  var prettyDate = updateDate.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" })
  var prettyTime = updateDate.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  })

  //Make API call and store results as flightData/flights
  const [flights, setFlights] = useState<FlightInfo[]>([])

  const getData = async () => {
    try {
      const data = await FlightClient.getFlightData('d')
      if(data[0]) {
        setFlights(data)
      }
      if (firstLoad) {
        setFirstLoad(false)
      }
    } catch(err) {
      setFlights([])
    }
  }

  useEffect(() => {
    lottie.loadAnimation({
      container: animationRef?.current,
      animationData: loader
    })
    getData()
    const interval = setInterval(() => {
      getData()
    }, 120000)
    return () => clearInterval(interval)
  }, [])
  //Sort flightData by Scheduled Date, save as new "values" array
  const values = Object.values(flights)
  values.sort(
    (a, b) => a?.departure?.scheduled > b?.departure?.scheduled ? 1 : -1
  )

  //Filter "values" from above by Departures Only (leg === "D")
  const departures = values?.filter(item => {
    return item.leg === "D"
  })

  const flightsByDate = useMemo(() => {
    const t = flights.reduce((groups:{[key:string]:FlightInfo[]}, flight) => {
      const date = flight?.departure?.scheduled.split("T")[0]
      if(!groups[date]) {
        groups[date] = []
      }
      groups[date].push(flight)
      return groups
    }, {}) 
    return t
  }, [flights])

  const dates = Object.keys(flightsByDate).sort()

  return (
    <>
      <div
        css={[
          tw`flex flex-col h-auto justify-center items-center w-screen top-0 bg-top bg-cover bg-no-repeat font-sans leading-normal`,
        ]}
        style={{ backgroundImage: "url('/imageHeavyTemplate.jpg')" }}
      >
        <div css={[tw`flex justify-center items-center pt-24 pb-16`]}>
          <h2 css={[tw`text-center text-3xl md:text-4xl font-extrabold`]}>
            {text?.title}
          </h2>
        </div>
        {firstLoad ?
           <div css={[tw`h-80`]} ref={animationRef} />
            :
            <>
              {flightsByDate[dates[0]] && <FlightsTable flights={flightsByDate[dates[0]]} flightHeaders={flightHeaders} />}
              {flightsByDate[dates[1]] && <FlightsTable flights={flightsByDate[dates[1]]} flightHeaders={flightHeaders} />}
            </>
          }
        {/* I was told to put the date/time of the API call, and the API calls once per page load, so this is the date we are using here. */}
        <span css={[tw`text-base text-blue-9/10 py-3`]}>
          {text?.lastUpdatedText}: {prettyDate} {prettyTime}{" "}
        </span>
      </div>
    </>
  )
}

export default DepartureFlights
